.Login {
  background-color: #aaa;
  background-image: url("./imgs/bg.jpg");
  background-size:100% 100%;
  height: 100%;
  display: flex;
  align-items: center;
  * {
    font-family: 微软雅黑;
  }
  .login-pic .login-pic-span,
  .login-input,
  .login-button,
  .login-free {
    border-radius: 4px;
  }
  .login {
    width: 850px;
    height: 550px;
    text-align: center;
    margin:  0 auto;
  }
  .login-left {
    width: 70%;
    height: 100%;
    float: left;
    background-color: #FFFFFF;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  .login-left-content{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .ant-row{
      justify-content: center;
    }
  }
  .login-right {
    width: 30%;
    height: 100%;
    float: right;
    color: #FFFFFF;
    background-color: #86cbc8;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .login-nav {
    font: normal 600 35px "宋体";
    margin-bottom: 24px;
  }
  .login-come {
    font: normal 600 22px "微软雅黑";
  }
  .login-pic .login-pic-span {
    width: 25px;
    height: 25px;
    padding: 5px;
    margin: 0 10px;
    border: 1px solid rgb(228, 228, 228);
  }
  .login-select {
    position: relative;
    bottom: 30px;
    right: 20px;
  }
  .login-input {
      width: 372px;
      height: 60px;
      line-height: 60px;
      border-radius: 6px;
      border: 1px solid #DBDBDB;
      padding: 0 10px;
      font-size: 16px;
      font-weight: 400;
      color: #222;
  }
  .login-select,
  .login-forget {
    color: #808080;
    font-size: 12px;
  }
  .login-forget {
    margin: 20px 0 20px 180px;
  }
  .login-button {
    background-color: #86cbc8;
    width: 372px;
    height: 60px;
    border-color: #86cbc8;
    box-shadow: 3px 3px 6px 0px #58b8b3;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 5px;
    margin-bottom: 24px;
  }
  .login-come {
    line-height: 30px;
    margin-top: 90px;
  }
  .login-message {
    font-size: 12px;
    line-height: 25px;
    margin-top: 15px;
    margin-bottom: 50px;
  }
  .login-free {
    font-size: 13px;
    padding: 6px 50px;
    border: 1px solid #FFF;
  }
}

@primary-color: #1890ff;