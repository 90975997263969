.updateModel{
    input[disabled]{
        color: inherit;
    }
}

.ant-picker{
    width: 100%;
}

.text-center{
    text-align: center;
}

.qrcode{
    text-align: center;
}

.text-info{
    padding-left: 5px;
}

#demo{
    border-color: black;
}

.text-style{
    font-weight: 900;
}

#a4Demo{
    font-weight: bolder;
}

table {
    
}


.table-input{
    display: inline-flex;
    margin-bottom: 0;
    line-height: 50px;
    
    input{
        max-width: 100%;
        border-color: transparent;
        font-size: 18px;
        font-weight: bolder;
        word-break: keep-all;
        white-space:pre-wrad;
        word-wrap:break-word;
    }
    .ant-input-number{
        border-color: transparent;
    }
    .ant-picker{
        border-color: transparent;
    }
    .anticon-calendar{
        visibility: hidden;
    }
}


.table-foot-1{
    border-left: 1px solid #000;
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
}

.table-foot-2{
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
}

.line13{
    line-height: 50px;
}

#table-main{
    border:solid 1px;
    th{
        font-size: 18px;
        border:solid 1px;
    }
}

@primary-color: #1890ff;