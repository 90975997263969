.updateModel{
    input[disabled]{
        color: inherit;
    }
}

.ant-picker{
    width: 100%;
}

.text-center{
    text-align: center;
}

.qrcode{
    text-align: center;
}

.text-info{
    padding-left: 5px;
}

#demo{
    border-color: black;
}

.text-style{
    font-weight: 900;
}

#a4Demo{
    
}

table {
    word-break:break-all !important;
}
@primary-color: #1890ff;