

.ant-card-extra{
    a{
        padding: 5px 10px;
        color: #aaa;
    }
    a:hover{
        background-color: #eee;
        color: #000;
    }
}

.ant-card-body{
    padding: 0;
}

.card-content{
    font-size: 14px;
    line-height: 20px;
    .card-tr{
        
        // border-bottom: 1px solid #ccc;
        .card-td{
            padding: 10px;
            box-sizing: border-box;
        }
        .notice-type{
            font-weight: bolder;
            color: red;
        }
    }

}

.card-content .card-tr:last-child{
    border-bottom: 0px;
}
@primary-color: #1890ff;