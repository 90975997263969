.Main {
  .logoTop {
    line-height: 50px;
    color: #fff;
    text-align: center;
    font-size: 20px;
    margin: 0px;
    cursor: pointer;
    // border:2px solid @primary-color;
  }
  .hide{
    display: none;
  }
  .ant-page-header-heading-title {
    color: aliceblue;
  }

  .bg-row{
    td{background-color: #f9f9f9;}
  }

  .searchForm{
      background-color: #fff;
      padding: 10px 10px 0px;
      margin: 10px 0;
      border-radius: 6px;
      box-sizing: border-box;
      .ant-row{
        flex-direction: row;
        align-items: center;
      }
      .ant-row .ant-form-item{
        margin-bottom: 10px;
      }
  }

  .table-box{
    padding: 10px 10px 0;
    background-color: #fff;
    border-radius: 6px;
    .ant-table-wrapper{
      margin-top: 10px;
    }
    .add-btn{
      border:#1c84c6;
      background-color: #1c84c6;
    }
    .stan-btn{
      border:#4a55c0;
      background-color: #4a55c0;
    }
    .import-btn{
      border:#23c6c8;
      background-color: #23c6c8;
    }
    .export-btn{
      border:#f8ac59;
      background-color: #f8ac59;
    }
    
  }

  .mybtn{
    height: 30px;
    font-size: 12px;
    padding: 5px 10px;
    margin-right: 10px;
    border-radius: 3px;
    color: #fff;
    box-sizing: border-box;
    cursor: pointer;
  }

  .search-btn{
    background-color: #1ab394;
    border-color: #1ab394;
    color: #fff;
    border-radius: 50px;
  }

  

  .clean-btn{
    background-color: #f8ac59;
    border-color: #f8ac59;
    color: #fff;
    border-radius: 50px;
  }

  .bianji{
    font-size: 12px;
    height: 22px;
    padding: 1px 6px;
    margin-right: 10px;
    border-radius: 3px;
    color: #FFFFFF;
    background-color: #1c84c6;
    span:last-child{
      margin-left: 4px;
    }
  }

  .quxiao{
    font-size: 12px;
    height: 22px;
    padding: 1px 6px;
    margin-right: 10px;
    border-radius: 3px;
    color: #FFFFFF;
    background-color: red;
    span:last-child{
      margin-left: 4px;
    }
  }

  .chakan{
    font-size: 12px;
    height: 22px;
    padding: 1px 6px;
    margin-right: 10px;
    border-radius: 3px;
    color: #FFFFFF;
    background-color: #1ab394;
    span:last-child{
      margin-left: 4px;
    }
  }

  .zhiding{
    font-size: 12px;
    height: 22px;
    padding: 1px 6px;
    margin-right: 10px;
    border-radius: 3px;
    color: #FFFFFF;
    background-color: #55caa3;
    span:last-child{
      margin-left: 4px;
    }
  }

  .xianqing{
    font-size: 12px;
    height: 22px;
    padding: 1px 6px;
    margin-right: 10px;
    border-radius: 3px;
    color: #FFFFFF;
    background-color: #23c6c8;
    span:last-child{
      margin-left: 4px;
    }
  }
  .shanchu{
    font-size: 12px;
    height: 22px;
    padding: 1px 6px;
    border-radius: 3px;
    color: #FFFFFF;
    background-color:#ff4d4f;
    span:last-child{
      margin-left: 4px;
    }
  }
  .xinxi{
    padding-left:5px;
    padding-right:5px;
    height:22px;
    background-color:#1ab394;
    font-size:13px;
    border-radius:2px;
    color:#ffffff
  }
  .xinxi:hover{
    background-color:#18a689;
  }
  .qiyon{
    font-size: 12px;
    height: 22px;
    padding: 1px 6px;
    border-radius: 3px;
    color: #FFFFFF;
    background-color:#1ab394;
    span:last-child{
      margin-left: 4px;
    }
  }
  .ant-layout-sider,.ant-menu .ant-menu-dark,.ant-menu.ant-menu-dark {
    background-color: rgba(15,41,80,1) !important;
  }

  .ant-menu-dark .ant-menu-sub{
    background-color: rgb(9, 30, 63) !important;
  }

  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected{
    background-color: #1890ff;
  }

  .ant-layout-header{
    line-height: 50px;
  }

  .ant-page-header-heading-left {
    margin-left: auto;
  }

  .ant-layout-header{
    height: 50px;
  }

  .ant-page-header{
    line-height: 50px;
    padding: 0px;
  }

  .ant-page-header-heading-extra{
    margin: 0px;
  }

  .header-left{
    flex-direction: row;
    line-height: 50px;
    height: 50px;
    flex: none;
    width: 100%;
    background-color: #3c8dbc;
    ul{
      list-style: none;
      margin: 0px;
      padding: 0px;
    }
    .u-right{
      .dropdown-menu{
        position:absolute;
        top: 50px;
        right: 0px;
        width: 160px;
        background: #fff;
        text-align: center;
        border: medium none;
        border-bottom-left-radius: 5px;
        box-shadow: 0 0 3px rgb(86 96 117 / 30%);

        li{
          line-height: 40px;
        }
        a{
          border-radius: 3px;
          color: inherit;
          margin: 4px;
          font-weight: normal;
          font-size: 14px;
          padding: 6px 30px;
        }
        a:hover{
          background-color: #e1e3e9;
        }
      }
    }
    .u-center{
      margin: auto;
    }
    
    .u-right {
      .item:hover{
        background-color: #3674a9;
      }
      .item{
        margin-left: 10px;
        float: right;
        cursor: pointer;
        li:hover{
          background-color: #337ab7;
        }
      }
    }
  }
  .ant-layout-footer{
    line-height: 16px;
    padding: 10px 20px;
    box-sizing: border-box;
  }
}


@primary-color: #1890ff;