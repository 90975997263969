
 .tupan {
   position: absolute;
   top:130px;
   border-radius: 3px;
   transform: rotate(329deg);
   height: 18px;
   width: 50px;
   text-align: center;
   background-color: #1c84c6;
   color: #ffffff;
   font-size: 12px;
 }
 .tupan2 {
   position: absolute;
   top:130px;
   border-radius: 3px;
   transform: rotate(329deg);
   height: 18px;
   width: 50px;
   text-align: center;
   background-color: #f8ac59;
   color: #ffffff;
   font-size: 12px;
 }

 #pdf{
   padding:0 20px;
   
   .po_head_pdf{
    font-family: "SimHei";
    font-weight: bold;
    .pdf_head{
      padding-top: 10px;
    }
    .pdf_body{
     padding-top: 5px;
    }
   }

   .po_main_pdf{
     
    td ,th{ 
      border-bottom:1px solid #000;  
      border-left:1px solid #000;  
    }
    padding-top: 10px;
    .pdf_head{
      padding-top: 10px;
    }
   }

   .tables{
    border:1px solid #000;
    border-top:0px ;
   }

   .foot_table{
    font-family: "SimHei";
    font-weight: bold;
    border:1px solid #000;
    border-top:0px ;
    .foot_table_right{
      border-right: 1px solid #000;
    }
   }
 }


 .confirmdate{
   border : 0px;
 }
 .confirmdate:hover{
  border : 0px;
  box-shadow:none;
 }

 .modelIcon{
  padding: 0 5px;
   cursor: pointer;
 }

 .reply:hover{
  background-color: #ccc;
 }

.cvenremark , .cvInput{
  border: 0px;
  margin: 0px;
}

.cvInput:hover , .cvenremark:hover{
  border : 0px;
  box-shadow:none;
 }


 #top{
  display: flex;
  flex-direction:row;
  #top-text{
    margin-left: 40px;
  }
  .top-text-1{
    letter-spacing : 10px;
    text-align: center;
  }
  .top-text-2{
    letter-spacing : 2px;
    text-align: center;
    margin-top: 10px;
  }
 }

@primary-color: #1890ff;